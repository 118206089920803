import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import { useMeta, useContentfulSection } from "utils"

import { PrimaryNav, AppStickyNav, MobileNav } from "src/components/navigation"
import { FaxCompareTable } from "src/components/tables"

import { Layout, Seo } from "components/common"

import AppCompareTemplate from "src/templates/AppCompareTemplate.js"

const FaxComparePage = () => {
  const { fax, app_doc, page } = useStaticQuery(query)
  const meta = useMeta(page)

  const { fax_compare_hero } = useContentfulSection(page.sections)
  const slug = "fax"

  return (
    <StyledComparePage>
      <Layout>
        <Seo {...meta} />

        <MobileNav slug={slug} />
        <PrimaryNav slug={slug} />
        <div className="nav-background">
          <AppStickyNav
            disabled={app_doc.disabled}
            slug={slug}
            salesforceAppexchangeUrl={app_doc.salesforceAppexchangeUrl}
          />
        </div>
        <AppCompareTemplate
          section={fax_compare_hero}
          app={app_doc}
          slug={slug}
          table={() => (
            <FaxCompareTable
              slug={slug}
              table={fax}
              switcherTitle="compare Fax by opero to:"
            />
          )}
        />
      </Layout>
    </StyledComparePage>
  )
}

const StyledComparePage = styled.div`
  .nav-background {
    --section-background-color: none;
  }
`

export const query = graphql`
  {
    fax: allGoogleSpreadsheetFax {
      edges {
        node {
          id
          # num
          feature
          opero
          eFax
          zPaper
        }
      }
    }
    page: contentfulPage(pageId: { eq: "fax_compare" }) {
      ...Page
    }
    app_doc: contentfulApp(slug: { eq: "fax" }) {
      ...App
    }
  }
`

export default FaxComparePage
